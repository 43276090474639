import React from 'react';
import { Row, Col, Button } from 'antd';
import { withTranslation } from '../src/i18n/i18n';

const Error = ({ t }) => {
  return (
    <>
      <Row type="flex" justify="center" align="middle">
        <Col className="mt-70" align="center" xs={20} md={16} lg={8}>
          <img className="img-fluid" src="/static/alien.png" alt="img" />
        </Col>
        <Col align="center" span={24}>
          <span style={{ fontSize: 100, color: '#dcdcdc', fontWeight: 'bold' }}>404</span>
        </Col>
        <Col align="center" span={24}>
          <span className="text-18-bold">{t('pageNotFound')}</span>
        </Col>
        <Col className="mt-50" align="center" xs={24} md={12}>
          <Button className="light-btn-fat">ΕΠΙΣΤΡΟΦΗ ΣΤΟ ΚΑΤΑΣΤΗΜΑ</Button>
        </Col>
      </Row>
    </>
  );
};

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : null;
  return { statusCode, namespacesRequired: ['error'] };
};

export default withTranslation('error')(Error);
